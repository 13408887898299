// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { WISHLIST_TOAST } from '@common/store/wishList/slice';
import Close from '@images/blackClose.svg';
import whiteClose from '@images/whiteClose.svg';
import classnames from 'classnames';
import React from 'react';

import { isMobile } from '../../../../common/utils';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  Details?: boolean;
  viewCart?: () => void;
  productImage?: string;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView, productImage, Details }) => {
  setTimeout(() => {
    if (toasts.length && !isMobile()) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);
  const isWishlist = (toast) => {
    return toast?.options?.find((option) => option?.name == WISHLIST_TOAST);
  };

  let Toastcss = '';
  let errorBg = '';
  let successBg = '';
  if (Details) {
    errorBg = 'bg-esteem-error';
    successBg = 'bg-esteem-toast ';
    Toastcss = 'border  text-16 text-full_black mx-4 md:mx-0 my-16 font-normal rounded-10 shadow-esteem-toast';
  } else {
    errorBg = 'bg-esteem-error';
    successBg = 'bg-esteem-toast ';
    Toastcss =
      'text-full_black text-18 leading-22 my-16 mx-4 md:mx-auto font-normal rounded-10 shadow-esteem-toast md:max-w-490';
  }
  return (
    <>
      {quickView ? (
        <div className={classnames('z-999 fixed bottom-0 md:top-0 md:absolute w-full md:h-72')}>
          {toasts.map((toast) => (
            <div key={toast.key} className="w-340 min-w-340 md:w-369 md:min-w-369 md:max-w-410 mx-auto">
              <div
                className={
                  toast.variant === 'bg-toast-error'
                    ? 'bg-esteem-error md:w-435  md:min-w-435 md:max-w-444 text-full_black mb-20 md:mb-24 rounded-5  shadow-esteem-toast border border-esteem-errorToast'
                    : 'bg-esteem-toast md:w-435 md:min-w-435 md:max-w-444 text-full_black mb-20 md:mb-24 rounded-5 shadow-esteem-toast border border-esteem-borderToast'
                }
              >
                <div
                  className={classnames(
                    'container mx-auto w-full font-normal flex items-center p-20 text-16 leading-22 justify-between',
                  )}
                  font-customisation="para-text"
                >
                  <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={whiteClose}
                    alt="Close"
                    className="ml-16 cursor-pointer h-14 hidden"
                    onClick={() => removeToast(toast.key)}
                  />
                  <img
                    src={Close}
                    alt="Close"
                    className="ml-16 cursor-pointer h-14  flex"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={classnames('w-full md:w-auto flex flex-wrap', {
            'md:justify-center z-50 container mx-auto w-full': Details,
            'justify-center fixed md:absolute mx-auto bottom-0 md:top-90 z-50': !Details,
          })}
        >
          <div className={classnames({ 'w-full z-50': Details })}>
            <div className={classnames('w-full')}>
              {toasts.map((toast) => {
                return (
                  <div key={toast.key}>
                    <div className="rounded-20 w-full md:m-0 md:mr-30 ">
                      <div
                        className={
                          toast.variant === 'bg-toast-error'
                            ? ` ${errorBg} border border-esteem-errorToast ${Toastcss}`
                            : ` ${successBg} border border-esteem-borderToast ${Toastcss}`
                        }
                      >
                        <div className="flex flex-row">
                          <div
                            className={classnames(
                              'container mx-auto w-full flex font-bold items-center  text-16 justify-start',
                              {
                                'p-10': Details,
                                'p-20 sm:w-300': !Details,
                              },
                            )}
                          >
                            {Details && !isWishlist(toast) ? (
                              <img
                                src={productImage}
                                className="flex mr-12 w-60 min-w-60 h-60 rounded-lg border-white border-2 object-contain"
                              />
                            ) : null}
                            <div
                              className={classnames('flex flex-col mr-6 ', {
                                'self-start justify-start ': Details,
                              })}
                            >
                              {Details && !isWishlist(toast) ? (
                                <div
                                  className={classnames('text-12 leading-17 font-medium text-full_black', {})}
                                  font-customisation="para-text"
                                >
                                  {toast.variant === 'bg-toast-error'
                                    ? 'Error Adding the product'
                                    : 'Successfully added to cart'}
                                </div>
                              ) : null}
                              <div
                                className={classnames('font-semibold text-16 leading-22', {
                                  'mt-6': Details && !isWishlist(toast),
                                })}
                                font-customisation="para-text"
                                dangerouslySetInnerHTML={{ __html: toast.content }}
                              ></div>
                            </div>
                          </div>
                          <div className={classnames('flex  flex-row ml-6 mt-2 py-2 p-30 self-center', {})}>
                            <img
                              src={Close}
                              alt="Close"
                              className="cursor-pointer z-999 min-w-14 min-h-14 h-14 w-14"
                              onClick={() => {
                                removeToast(toast.key);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toasts;
